import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const MiniProject = ({ menu_projects }) => (


    <article className="Film-entry js-filmEntry">
        <a
            href={'/project/' + menu_projects.id}> </a>
        <div className="Film-entryGraphic">
            <div className="Film-entryGraphicTitle"></div>
            <LazyLoadImage
                width="2560" height="1698"
                src={menu_projects.image}
                alt=""
            />
        </div>
        <h2 className="Film-entryTitle"><span>{menu_projects.title}</span></h2>
    </article>
);

export default MiniProject;
