import * as React from 'react';
import './App.css';
import useGaTracker from './useGaTracker'

import RouterPage from './pages/RouterPage'

function App() {
  useGaTracker();

  return (
    <div className='App'>
      <RouterPage />
    </div>
  );
}

export default App;


