import React from 'react';
// import dayjs from 'dayjs';


function toggleNav() {
    // console.log("hi");
    document.body.classList.toggle("is-state--menu")

}

const NavbarProject = ({ data }) => (
    <header className="Header js-header is-style--wideSplitGraphicHorizontal is-style--portraitGraphic is-style--landscapeGraphic is-style--griddedGraphic is-style--splitGraphicHorizontal">
        <h1 className="Header-name">
            <a href="/">  <h1>CRAEHOUSE</h1></a>

        </h1>
        <h2 class="Header-meta"><span>( {data.type} ) {data.title}</span><span>( TYPE ) {data.type2}</span><span>( TIME ) {data.lenght}</span></h2>

        <ul className="Header-menu">
            <li className="Header-menuItem Header-menuItem--about"><a href="/about/">About</a></li>
        </ul>
     

        <ToggleHamburger onButtonClick={toggleNav}/>

    </header>
);

function ToggleHamburger({ onButtonClick }) {
    return (
     <button className="Header-menuToggler js-toggleStateMenu" onClick={onButtonClick}>
         <div className="Header-menuTogglerLine Header-menuTogglerLine--top"></div>
         <div className="Header-menuTogglerLine Header-menuTogglerLine--middle"></div>
         <div className="Header-menuTogglerLine Header-menuTogglerLine--bottom"></div>
     </button>
    );
}

export default NavbarProject;
