import React from "react"
import Navbar from "../components/Navbar";

export default function NotFound() {

    return (
        <React.Fragment>
            <Navbar />


        </React.Fragment>
    );
}




