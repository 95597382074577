import { LazyLoadImage } from 'react-lazy-load-image-component';
import React from 'react';
// import dayjs from 'dayjs';

const Title = ({ data }) => (
    <div className="Title">
        <LazyLoadImage
            width="2560" height="1698"
            src="/assets/images/logo.png"
            alt=""
        />
    </div>
);

export default Title;
