import React from "react"
import { useEffect } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';


import Footer from "../components/Footer";
import Navbar from "../components/Navbar";


//projects import

export default function Project() {

  // < !-- < body className="film-template-default single single-film postid-653 is-loaded" style = "background-color:#0a0202;--color-base:#0a0202;color:#ffffff;--color-contrast:#ffffff;" data - cursor="pointer" > -->

  useEffect(() => {
    document.body.classList.add('film-template-default');
    document.body.classList.add('single');
    document.body.classList.add('single-film');
    document.body.classList.add('postid-653');
    document.body.classList.add('is-loaded');

    return () => {
      // document.body.classList.remove('home');
      // document.body.classList.remove('page-template-default');
      // document.body.classList.remove('page');
      // document.body.classList.remove('page-id-2');
      // document.body.classList.remove('page-startseite');
    };
  }, []);

  return (
    <React.Fragment>

      <div className="Cursor js-cursor is-visible" style={{ left: '430px', top: '879px' }} ><i></i></div>

      <Navbar />
      {/* 
      <div className="Loader js-loader">
        <div className="Loader-animation">
          <div className="Loader-animationText">Loading</div>
        </div>
      </div> */}

      <section className="Page-section Page-section--textImage js-section is-visible" data-background="#000000" data-layout="textImage">

        {/* <div className="Page-sectionMeta"> ( ABOUT ) DIRECTOR &amp; PHOTOGRAPHER BASED IN SLOVAKIA</div> */}
        <div className="Page-sectionText">


          <p className="p1">creative house for your next project.</p>
          <p className="p1">focusing on craetivity.</p>

          <p className="p1">three friends with different focus and /almost/ similar view of the world around.</p>
          <p className="p1">our goal is to create real brands and visuals that we will live by for a while.</p>
          <p className="p1">with projects and clients, we want to go into the deep first, not to width.</p>

          <p className="p1">our vision is being authentic, professional, close and practical.</p>






        </div>


        <div className="Page-sectionGraphic Page-sectionGraphic--half">
          {/* <div className="Page-sectionGraphicMeta"> ( ABOUT ) DIRECTOR &amp; PHOTOGRAPHER BASED IN SLOVAKIA</div> */}
          <LazyLoadImage
            width="2560" height="1698"
            src='/assets/images/about/s.jpg'
            alt=""
          />
        </div>
        <div className="Page-sectionGraphic Page-sectionGraphic--half">
          {/* <div className="Page-sectionGraphicMeta"> ( ABOUT ) DIRECTOR &amp; PHOTOGRAPHER BASED IN SLOVAKIA</div> */}
          <LazyLoadImage
            width="2560" height="1698"
            src='/assets/images/about/j.jpg'
            alt=""
          />
        </div>
        <div className="Page-sectionGraphic Page-sectionGraphic--half">
          {/* <div className="Page-sectionGraphicMeta"> ( ABOUT ) DIRECTOR &amp; PHOTOGRAPHER BASED IN SLOVAKIA</div> */}
          <LazyLoadImage
            width="2560" height="1698"
            src='/assets/images/about/t.jpg'
            alt=""
          />
        </div>


      </section>



      {/* <div className="Zoom js-zoomContainer"></div> */}
      <Footer />


    </React.Fragment >
  );
}




