import React from "react"
import { useEffect } from 'react';

import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import Title from "../components/Title";
// import ProjectInMenu from '../components/ProjectInMenu';
// import ProjectInMenu2 from '../components/ProjectInMenu2';
// import ProjectInMenu3 from '../components/ProjectInMenu3';
// import ProjectInMenu4 from '../components/ProjectInMenu4';
// import ProjectInMenu5 from '../components/ProjectInMenu5';
import data from '../data/menu_projects';
// import { LazyLoadImage } from 'react-lazy-load-image-component';

export default function Home() {
  // <!-- <body className="home page-template-default page page-id-33 section-page" data-cursor="film"> -->

  useEffect(() => {
    document.body.classList.add('home');
    document.body.classList.add('page-template-default');
    document.body.classList.add('page');
    document.body.classList.add('page-id-33');
    document.body.classList.add('section-page');

    return () => {
      // document.body.classList.remove('home');
      // document.body.classList.remove('page-template-default');
      // document.body.classList.remove('page');
      // document.body.classList.remove('page-id-2');
      // document.body.classList.remove('page-startseite');
    };
  }, []);

  // function Greeting(data, full) {
  //   if (data === "1") {
  //     return <ProjectInMenu menu_projects={full}
  //       key={data.title} />;
  //   } else if (data === "2") {
  //     return <ProjectInMenu2 menu_projects={full}
  //       key={data.title} />;
  //   } else if (data === "3") {
  //     return <ProjectInMenu3 menu_projects={full}
  //       key={data.title} />;
  //   } else if (data === "4") {
  //     return <ProjectInMenu4 menu_projects={full}
  //       key={data.title} />;
  //   } else if (data === "5") {
  //     return <ProjectInMenu5 menu_projects={full}
  //       key={data.title} />;
  //   }
  // };

  return (
    <React.Fragment>
      <div className="is-snap">

    {/* scroll-snap-type: y mandatory */}
        <Title />
        <Navbar />
        

     

        {/* <div className="Loader js-loader">
        <div className="Loader-animation">
          <div className="Loader-animationText">Loading</div>
        </div>
      </div> */}


        {/* DoubleVideo */}
        <section className="Page-section js-section Page-section--wideSplitGraphicHorizontal is-visible" data-background="#ffffff" data-text="#000000" data-layout="wideSplitGraphicHorizontal" data-type="film">
          <a className="Page-sectionLink" href={'/project/' + data[0].id}> </a>
          <h2 className="Page-sectionMeta"><span><a href={'/project/' + data[0].id}>( {data[0].type} ) </a>{data[0].title}</span><span>( TYPE ) {data[0].type2}</span><span>( TIME ) {data[0].lenght}</span></h2>
          <div className="Page-sectionGraphic Page-sectionGraphic--top">
            <div className="Page-sectionGraphicMeta"><span><a href={'/project/' + data[0].id}>( {data[0].type} ) </a>{data[0].title}</span><span>( TYPE ) {data[0].type2}</span><span>( TIME ) {data[0].lenght}</span></div>
            <video loop autoPlay muted >
              <source src={data[0].video} type="video/mp4" />
            </video>
          </div>
          <div className="Page-sectionGraphic Page-sectionGraphic--bottom">
            <div className="Page-sectionGraphicMeta"><span><a href={'/project/' + data[0].id}>( {data[0].type} ) </a>{data[0].title}</span><span>( TYPE ) {data[0].type2}</span><span>( TIME ) {data[0].lenght}</span></div>
            <video loop autoPlay muted >
              <source src={data[0].video} type="video/mp4" />
            </video>
          </div>
        </section>
    
        {/* VeryVideVideo */}
        <section className="Page-section js-section Page-section--veryWideGraphic is-visible" data-background="#ffaaff" data-text="#000000" data-layout="veryWideGraphic" data-type="film">
          <a className="Page-sectionLink" href={'/project/' + data[1].id}> </a>
          <h2 className="Page-sectionMeta"><span><a href={'/project/' + data[1].id}>( {data[1].type} ) </a>{data[1].title}</span><span>( TYPE ) {data[1].type2}</span><span>( TIME ) {data[1].lenght}</span></h2>
          <div className="Page-sectionGraphic Page-sectionGraphic--wide">
          <div className="Page-sectionGraphicMeta"><span><a href={'/project/' + data[1].id}>( {data[1].type} ) </a>{data[1].title}</span><span>( TYPE ) {data[1].type2}</span><span>( TIME ) {data[1].lenght}</span></div>
              <video loop autoPlay muted >
              <source src={data[1].video} type="video/mp4" />
              </video>
          </div>
        </section>


      {/* TilesVideo */}
      <section className="Page-section js-section Page-section--griddedGraphic is-visible" data-background="#bf1818" data-text="#ffffff" data-layout="griddedGraphic" data-type="film">
        <a className="Page-sectionLink" href={'/project/' + data[3].id}> </a>
        <h2 className="Page-sectionMeta"><span><a href={'/project/' + data[3].id}>( {data[3].type} ) </a>{data[3].title}</span><span>( TYPE ) {data[3].type2}</span><span>( TIME ) {data[3].lenght}</span></h2>
        <div className="Page-sectionGraphic Page-sectionGraphic--grid Page-sectionGraphic--gridOne">
        <video loop autoPlay muted >    
            <source src={data[3].video} type="video/mp4" />
            </video>
        </div>
        <div className="Page-sectionGraphic Page-sectionGraphic--grid Page-sectionGraphic--gridTwo">
        <video loop autoPlay muted >    
            <source src={data[3].video} type="video/mp4" />
            </video>
        </div>
        <div className="Page-sectionGraphic Page-sectionGraphic--grid Page-sectionGraphic--gridThree">
        <video loop autoPlay muted >    
            <source src={data[3].video} type="video/mp4" />
            </video>
        </div>
        <div className="Page-sectionGraphic Page-sectionGraphic--grid Page-sectionGraphic--gridFour">
        <video loop autoPlay muted >    
            <source src={data[3].video} type="video/mp4" />
            </video>
        </div>
        <div className="Page-sectionGraphic Page-sectionGraphic--grid Page-sectionGraphic--gridFive">
        <video loop autoPlay muted >    
            <source src={data[3].video} type="video/mp4" />
            </video>
        </div>
        <div className="Page-sectionGraphic Page-sectionGraphic--grid Page-sectionGraphic--gridSix">
        <video loop autoPlay muted >    
            <source src={data[3].video} type="video/mp4" />
            </video>
        </div>
        <div className="Page-sectionGraphic Page-sectionGraphic--grid Page-sectionGraphic--gridSeven">
        <video loop autoPlay muted >
                <source src={data[3].video} type="video/mp4" />
            </video>
        </div>
        <div className="Page-sectionGraphic Page-sectionGraphic--grid Page-sectionGraphic--gridEight">
        <video loop autoPlay muted >    
          <source src={data[3].video} type="video/mp4" />
            </video>
        </div>
      </section>


        {/* SmallVideVideo */}
        <section className="Page-section js-section Page-section--landscapeGraphic is-visible" data-background="#0a0000" data-text="#ddaf6a" data-layout="landscapeGraphic" data-type="film">
        <a className="Page-sectionLink" href={'/project/' + data[2].id}> </a>
        <h2 className="Page-sectionMeta"><span><a href={'/project/' + data[2].id}>( {data[2].type} ) </a>{data[2].title}</span><span>( TYPE ) {data[2].type2}</span><span>( TIME ) {data[2].lenght}</span></h2>
        <div className="Page-sectionGraphic Page-sectionGraphic--landscape">
          <div className="Page-sectionGraphicMeta"><span><a href={'/project/' + data[2].id}>( {data[2].type} ) </a>{data[2].title}</span><span>( TYPE ) {data[2].type2}</span><span>( TIME ) {data[2].lenght}</span></div>
          <video loop autoPlay muted >
          <source src={data[2].video}  type="video/mp4" />
          </video>
        </div>
      </section>


      {/* Portrait Image */}
      {/* <section className="Page-section js-section Page-section--portraitGraphic is-visible" data-background="#e5b816" data-text="#ffffff" data-layout="portraitGraphic" data-type="photo">
        <a className="Page-sectionLink" href="https://faramarz.se/photo/seinabo/"> </a>
        <h2 className="Page-sectionMeta"><span><a href="https://faramarz.se/photo/">( Photo ) </a>SEINABO SEY</span><span>( CLIENT ) Seinabo Sey –&nbsp;Radio Garden</span><span>( QUANTITY ) 12</span></h2>
        <div className="Page-sectionGraphic Page-sectionGraphic--portrait"><img alt="" src="./Faramarz Gosheh_files/SEINABO_FG-28.jpg" srcSet="https://faramarz.se/wordpress/wp-content/uploads/2022/04/SEINABO_FG-28.jpg 2321w, https://faramarz.se/wordpress/wp-content/uploads/2022/04/SEINABO_FG-28-199x300.jpg 199w, https://faramarz.se/wordpress/wp-content/uploads/2022/04/SEINABO_FG-28-679x1024.jpg 679w, https://faramarz.se/wordpress/wp-content/uploads/2022/04/SEINABO_FG-28-768x1158.jpg 768w, https://faramarz.se/wordpress/wp-content/uploads/2022/04/SEINABO_FG-28-1019x1536.jpg 1019w, https://faramarz.se/wordpress/wp-content/uploads/2022/04/SEINABO_FG-28-1358x2048.jpg 1358w, https://faramarz.se/wordpress/wp-content/uploads/2022/04/SEINABO_FG-28-66x100.jpg 66w, https://faramarz.se/wordpress/wp-content/uploads/2022/04/SEINABO_FG-28-2037x3072.jpg 2037w" width="2321" height="3500" loading="lazy"/></div>
      </section>  */}


         {/* LandscapeImage */}
       {/* <section className="Page-section js-section Page-section--landscapeGraphic is-visible" data-background="#590017" data-text="#ffffff" data-layout="landscapeGraphic" data-type="photo">
        <a className="Page-sectionLink" href="https://faramarz.se/photo/loplabbet-sa/"> </a>
        <h2 className="Page-sectionMeta"><span><a href="https://faramarz.se/photo/">( Photo ) </a>Löplabbet - Nobody Runs Like You</span><span>( TYPE ) Commerical</span><span>( QUANTITY ) 14</span></h2>
        <div className="Page-sectionGraphic Page-sectionGraphic--landscape">
            <div className="Page-sectionGraphicMeta"><span><a href="https://faramarz.se/photo/">( Photo ) </a>Löplabbet - Nobody Runs Like You</span><span>( TYPE ) Commerical</span><span>( QUANTITY ) 14</span></div>
            <img alt="" src="./Faramarz Gosheh_files/LOPLABBET_PORTFOLIO-13.jpg" srcSet="https://faramarz.se/wordpress/wp-content/uploads/2023/03/LOPLABBET_PORTFOLIO-13.jpg 1920w, https://faramarz.se/wordpress/wp-content/uploads/2023/03/LOPLABBET_PORTFOLIO-13-300x199.jpg 300w, https://faramarz.se/wordpress/wp-content/uploads/2023/03/LOPLABBET_PORTFOLIO-13-1024x679.jpg 1024w, https://faramarz.se/wordpress/wp-content/uploads/2023/03/LOPLABBET_PORTFOLIO-13-768x509.jpg 768w, https://faramarz.se/wordpress/wp-content/uploads/2023/03/LOPLABBET_PORTFOLIO-13-1536x1018.jpg 1536w, https://faramarz.se/wordpress/wp-content/uploads/2023/03/LOPLABBET_PORTFOLIO-13-100x66.jpg 100w" width="1920" height="1273" loading="lazy"/>
        </div>
      </section> */}


    {/* VeryVideVideo */}
    {/* <section className="Page-section js-section Page-section--veryWideGraphic is-visible" data-background="#ffaaff" data-text="#000000" data-layout="veryWideGraphic" data-type="film">
          <a className="Page-sectionLink" href={'/project/' + data[4].id}> </a>
          <h2 className="Page-sectionMeta"><span><a href={'/project/' + data[4].id}>( {data[4].type} ) </a>{data[4].title}</span><span>( TYPE ) {data[4].type2}</span><span>( TIME ) {data[4].lenght}</span></h2>
          <div className="Page-sectionGraphic Page-sectionGraphic--wide">
          <div className="Page-sectionGraphicMeta"><span><a href={'/project/' + data[4].id}>( {data[4].type} ) </a>{data[4].title}</span><span>( TYPE ) {data[4].type2}</span><span>( TIME ) {data[4].lenght}</span></div>
              <video loop autoPlay muted >
              <source src={data[4].video} type="video/mp4" />
              </video>
          </div>
        </section> */}


     

 

        {/* {data.map((menuprojects) => (
          Greeting(menuprojects.postType, menuprojects)
        ))} */}

        {/* <div className="Zoom js-zoomContainer"></div> */}

        <Footer />

      </div>

    </React.Fragment >
  );
}




