import React from 'react';
// import dayjs from 'dayjs';
import projectData from '../data/menu_projects';
import MiniProject from './MiniProject';

const projects_numbers = [];
projects_numbers.push(0, 1, 2, 3);
shuffle(projects_numbers);

const NextProjects = ({ data }) => (
    
    <div className="Film Film--related">

            <MiniProject
                menu_projects={projectData[projects_numbers[0]]}/>
       
            <MiniProject
                menu_projects={projectData[projects_numbers[1]]}/>

    </div>
);


function shuffle(array) {
    let currentIndex = array.length;
  
    // While there remain elements to shuffle...
    while (currentIndex !== 0) {
  
      // Pick a remaining element...
      let randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
  
      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }
  }


export default NextProjects;
