import React from 'react'
import { Routes, Route } from 'react-router-dom'

import Home from './Home'
import Project from './Project'
import NotFound from './NotFound'
import About from './About'

export default function RouterPage() {
    return (
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            {/*   <Route path="/film" element={<Film />} />
            <Route path="/random" element={<Random />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/data-protection" element={<DataProtection />} />
            {/* todo limit projects in list for roouter */}
            <Route path="/project/:id" element={<Project />} />
            <Route path="*" element={<NotFound />} />
        </Routes>
    )
}