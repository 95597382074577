import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const Footer2 = ({ data }) => (

  <footer className="Footer2">
    <LazyLoadImage
      className="Svg"
      width="2560" height="1698"
      src="/assets/images/logo.png"
      alt=""
    />
    <div className="Footer-details">
      <p>( CONTACT )
        <br></br>
        <a href="mailto:hello@crae.house" rel="noopener">hello@crae.house</a> • <a href="https://www.instagram.com/craehouse/" rel="noopener">@craehouse</a>
      </p>
    </div>
    <LazyLoadImage
      width="2560" height="1698"
      src="/assets/images/logo.png"
      alt=""
    />
  </footer>
);

export default Footer2;
