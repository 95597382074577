import React from "react"
import { useEffect } from 'react';
import { useParams } from "react-router-dom";

import Footer2 from "../components/Footer2";
import NavbarProject from "../components/NavbarProject";
import NextProjects from "../components/NextProjects";

// //projects import
import menu_projects from '../data/menu_projects';

export default function Project() {
  const { id } = useParams();

  var index = menu_projects.findIndex(function (item, i) {
    return item.id === id
  });

  var found = menu_projects[index];

  // < !-- < body class="film-template-default single single-film postid-653 is-loaded" style = "background-color:#0a0202;--color-base:#0a0202;color:#ffffff;--color-contrast:#ffffff;" data - cursor="pointer" > -->

  useEffect(() => {
    document.body.classList.add('film-template-default');
    document.body.classList.add('single');
    document.body.classList.add('single-film');
    document.body.classList.add('postid-51');
    document.body.classList.add('is-loaded');

    return () => {
      // document.body.classList.remove('home');
      // document.body.classList.remove('page-template-default');
      // document.body.classList.remove('page');
      // document.body.classList.remove('page-id-2');
      // document.body.classList.remove('page-startseite');
    };
  }, []);

  return (
    <React.Fragment>

      {/* <div className="Cursor js-cursor is-visible" style={{ left: '430px', top: '879px' }} ><i></i></div> */}

      <NavbarProject data={found} />

      <main className="Entry">
        <div className="jakubko_test2">
          <iframe title={found.title} className="jakubko_test" src={found.src}
            allowfullscreen scrolling="no"
            allow="encrypted-media;">
          </iframe>
        </div>

        <div className="Entry-details">
          <h1 className="Entry-title">{found.title}</h1>
          <p>{found.desc}</p>

          <br></br>
          <br></br>
          <p className="Entry-subtitle">Songs performed by:</p>

          {found.client_people.map((x) => (
            <p>({x.title}) {x.person}</p>

          ))}

          <br></br>
          <br></br>
          <p className="Entry-subtitle">Video credits:</p>
          {found.production_people.map((x) => (
            <p>({x.title}) {x.person}</p>

          ))}

        </div>


        <div className="Entry-stills">
          {found.pictures.map((pic) => (
            <figure className="Entry-stillsImage Entry-stillsImage--paddingRegular Entry-stillsImage--verticalAlignmentCenter Entry-stillsImage--horizontalAlignmentCenter js-lazyImage js-zoomImage">
              <img width="3000" height="1257" srcSet="" className="attachment-full size-full" alt="" decoding="async" loading="lazy" sizes="(max-width: 767px) 100vw, 50vw"
                srcset={pic} />
            </figure>
          ))}
        </div>


      </main>


      <div className="Zoom js-zoomContainer"></div>

      < NextProjects data={found}  />
      <Footer2 />

    </React.Fragment >
  );
}




